.scheduler-container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.dhx_cal_data {
    pointer-events: none;
}
.dhx_cal_larea {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
}

.dhx_cal_light {
    z-index: 10000;
}

.dhx_cal_light_wide .dhx_cal_lsection {
    float: none;
    margin: 0;
    width: auto;
    text-align: center;
}

.dhx_cal_light_wide .dhx_cal_ltext {
    margin: 5px;
}

.dhx_cal_light_wide .dhx_cal_ltext select:disabled {
    background:rgba(0, 0, 0, .08);
}

.dhx_cal_light_wide .dhx_wrap_section {
    padding: 0 15px;
    margin: 0;
}

.dhx_wrap_section:nth-child(1) textarea {
    height: 32px;
}

.dhx_wrap_section:nth-child(1),
.dhx_wrap_section:nth-child(2) {
    margin: 0;
    grid-row: 1;
}

.dhx_wrap_section:nth-child(3) {
    grid-row: 2;
    grid-column: 1 / span 2;
}

.dhx_wrap_section:nth-child(4) {
    grid-row: 3;
    grid-column: 1 / span 2;
}

.dhx_wrap_section:nth-child(5) {
    grid-row: 4;
    grid-column: 1 / span 2;
}

.dhx_wrap_section:nth-child(6) {
    grid-row: 5;
    grid-column: 1 / span 2;
}

.dhx_wrap_section:nth-child(6) .dhx_cal_ltext input {
    box-sizing: border-box;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, .08);
    border-radius: 2px;
    font-family: Roboto, Arial;
    font-size: 14px;
    color: rgba(0, 0, 0, .8);
    padding: 2px 2px 2px 8px;
    margin: 0 8px 16px 0;
    width: 100%;
}

.dhx_wrap_section:nth-child(7) {
    grid-row: 6;
    grid-column: 1 / span 2;
    text-align: center;
}

.dhx_wrap_section:nth-child(8),
.dhx_wrap_section:nth-child(9) {
    grid-row: 7;
}

.dhx_wrap_section:nth-child(10) {
    grid-row: 8;
    grid-column: 1 / span 2;
    text-align: center;
}

.dhx_wrap_section:nth-child(10) .dhx_cal_wide_checkbox input {
    width: 25px;
    height: 25px;
    font-size: 32px;
}

.dhx_wrap_section:nth-child(3) .dhx_cal_ltext textarea,
.dhx_wrap_section:nth-child(4) .dhx_cal_ltext textarea {
    text-align: center;
    height: 30px;
}

.dhx_cal_lsection .dhx_fullday {
    line-height: 30px;
}